// React
import React from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Utilities
import { localize, localizeLink } from 'utilities';
import { useTheme } from 'hooks';
import { transitionNavigate } from 'transition';

// Components
import SEO from 'components/seo';
import { PageNavigation } from 'components/pageNavigation';
import PageIntroduction from 'components/pageIntroduction';
import ImageAndText from 'components/imageAndText';
import Image from 'components/image';
import Button from 'components/button';

const AboutPageComponent = ({ pageContext, content, location }) => {
    const { locale, labels } = pageContext;
    const {
        seoMetaTags,
        title,
        introduction,
        sections,
        founders,
        foundersImage,
    } = content;

    // Set theme
    const theme = useTheme(s.color('white'));

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <ContentWrapper>
                    <PageNavigation {...{ theme }}>
                        <span>{title}</span>
                    </PageNavigation>
                    <PageIntroduction {...{ content: introduction }} />
                    <ImageAndText
                        {...{
                            title: labels.PAGES.ABOUT.FOUNDERS,
                            customContent: founders.map((item, index) => (
                                <Founder key={index}>
                                    <span>{item.name}</span>
                                    <span>{item.title}</span>
                                </Founder>
                            )),
                            image: (
                                <Image
                                    {...{
                                        source: foundersImage.fluid,
                                        alt: labels.PAGES.ABOUT.FOUNDERS,
                                    }}
                                />
                            ),
                        }}
                    />
                    {sections.map((section, index) => (
                        <ImageAndText
                            key={`${section.name}-${index}`}
                            {...{
                                title: section.name,
                                text: section.pageDescription,
                                imagePosition:
                                    (index + 1) % 2 ? 'right' : 'left',
                                image: (
                                    <Image
                                        {...{
                                            source: section.pageImage.fluid,
                                            alt: section.name,
                                        }}
                                    />
                                ),
                                button: (
                                    <Button
                                        {...{
                                            onClick() {
                                                transitionNavigate({
                                                    from: location,
                                                    to: localizeLink(
                                                        `/${
                                                            section.__typename ===
                                                            'DatoCmsBlogSection'
                                                                ? 'stories/'
                                                                : ''
                                                        }${
                                                            section.pageLink[0]
                                                                .slug
                                                        }/`,
                                                        locale
                                                    ),
                                                });
                                            },
                                            type: 'secondary',
                                            label: section.pageLinkTitle,
                                            arrow: true,
                                        }}
                                    />
                                ),
                            }}
                        />
                    ))}
                </ContentWrapper>
            </s.layout.PageContainer>
        </>
    );
};

const ContentWrapper = styled.div`
    ${s.responsive.property('padding-top', {
        0: 100,
        30: 140,
    })};
`;

const Founder = styled.div`
    span {
        display: block;
        &:first-of-type {
            ${s.fonts.style('h8')};
        }
        &:last-of-type {
            ${s.fonts.style('h17')};
        }
    }
    margin-bottom: 1.5em;
    &:last-of-type {
        margin-bottom: 0;
    }
`;

const NamedAboutPageComponent = props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPageAbout } = useStaticQuery(graphql`
        query {
            allDatoCmsPageAbout {
                edges {
                    node {
                        id
                        locale
                        title
                        introduction
                        slug
                        founders {
                            name
                            title
                        }
                        foundersImage {
                            fluid(maxWidth: 800) {
                                ...GatsbyDatoCmsFluid_noBase64
                            }
                        }
                        seoMetaTags {
                            ...SEO
                        }
                        sections {
                            __typename
                            ... on DatoCmsBlogSection {
                                name
                                pageLink {
                                    slug
                                }
                                pageLinkTitle
                                pageDescription
                                pageImage {
                                    fluid(maxWidth: 800) {
                                        ...GatsbyDatoCmsFluid_noBase64
                                    }
                                }
                            }
                            ... on DatoCmsPageSection {
                                name
                                pageLinkTitle
                                pageDescription
                                pageImage {
                                    fluid(maxWidth: 800) {
                                        ...GatsbyDatoCmsFluid_noBase64
                                    }
                                }
                                pageLink {
                                    __typename
                                    ... on DatoCmsPageContact {
                                        slug
                                    }
                                    ... on DatoCmsPageCourse {
                                        slug
                                    }
                                    ... on DatoCmsPageGuide {
                                        slug
                                    }
                                    ... on DatoCmsPageAbout {
                                        slug
                                    }
                                    ... on DatoCmsPageStory {
                                        slug
                                    }
                                    ... on DatoCmsPageSustainability {
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <AboutPageComponent
            {...{
                content: localize(allDatoCmsPageAbout, locale),
                ...props,
            }}
        />
    );
};

export default NamedAboutPageComponent;
